@import "../../common/global.scss";

.locations {
  .buttons {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .buttons-title {
      font-family: Roboto-Medium;
      font-size: 32px;
      letter-spacing: 0;
    }
    .button-new {
      width: fit-content;
    }
  }
  .cell-image {
    font-size: 50px;
    line-height: 40px;
  }

}

.locations-edit {
    text-align: left;
    .banner {
        margin-bottom: 24px;
    }
    .delete {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
