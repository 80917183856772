@font-face {
  font-family: "Roboto-Thin";
  //font-style: normal;
  //font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-100.eot"),
    /* IE9 Compat Modes */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-100.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-100.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-100.woff")
      format("woff"),
    /* Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-100.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-100.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-100italic - cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese */
@font-face {
  font-family: "Roboto-ThinItalic";
  //font-style: italic;
  //font-weight: 100;
  src: local("Roboto Thin Italic"), local("Roboto-ThinItalic"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-100italic.eot"),
    /* IE9 Compat Modes */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-100italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-100italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-100italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-100italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-100italic.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-300 - cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese */
@font-face {
  font-family: "Roboto-Light";
  //font-style: normal;
  //font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-300.eot"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-300.woff")
      format("woff"),
    /* Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-300.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-300italic - cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese */
@font-face {
  font-family: "Roboto-LightItalic";
  //font-style: italic;
  //font-weight: 300;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-300italic.eot"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-300italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-300italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-300italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-300italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-300italic.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-regular - cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese */
@font-face {
  font-family: "Roboto-Regular";
  //font-style: normal;
  //font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-regular.eot"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-regular.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-italic - cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese */
@font-face {
  font-family: "Roboto-Italic";
  //font-style: italic;
  //font-weight: 400;
  src: local("Roboto Italic"), local("Roboto-Italic"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-italic.eot"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-italic.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-500 - cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese */
@font-face {
  font-family: "Roboto-Medium";
  //font-style: normal;
  //font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-500.eot"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-500.woff")
      format("woff"),
    /* Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-500.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-500italic - cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese */
@font-face {
  font-family: "Roboto-MediumItalic";
  //font-style: italic;
  //font-weight: 500;
  src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-500italic.eot"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-500italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-500italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-500italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-500italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-500italic.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-700 - cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese */
@font-face {
  font-family: "Roboto-Bold";
  //font-style: normal;
  //font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-700.eot"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-700.woff")
      format("woff"),
    /* Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-700.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-700italic - cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese */
@font-face {
  font-family: "Roboto-BoldItalic";
  //font-style: italic;
  //font-weight: 700;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-700italic.eot"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-700italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-700italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-700italic.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-900 - cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese */
@font-face {
  font-family: "Roboto-Black";
  //font-style: normal;
  //font-weight: 900;
  src: local("Roboto Black"), local("Roboto-Black"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-900.eot"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-900.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-900.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-900.woff")
      format("woff"),
    /* Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-900.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-900italic - cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese */
@font-face {
  font-family: "Roboto-BlackItalic";
  //font-style: italic;
  //font-weight: 900;
  src: local("Roboto Black Italic"), local("Roboto-BlackItalic"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-900italic.eot"),
    url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-900italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-900italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-900italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-900italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/roboto-v18-cyrillic-ext_greek_cyrillic_latin-ext_greek-ext_latin_vietnamese-900italic.svg#Roboto")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "restroomalert";
  src: url("/assets/fonts/restroomalert-Regular.woff2") format("woff2"),
    url("/assets/fonts/restroomalert-Regular.woff") format("woff"),
    url("../../assets/fonts/restroomalert-Regular.woff2") format("woff2"),
    url("../../assets/fonts/restroomalert-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
[class^="ra-icon-"]:before,
[class*=" ra-icon-"]:before {
  font-family: "restroomalert";
  font-style: normal;
  speak: none;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.ra-icon-chevron:before {
  content: "\e065";
}
.ra-icon-menu:before {
  content: "\e037";
}
.ra-icon-back:before {
  content: "\e041";
}
.ra-icon-cross:before {
  content: "\e027";
}
.ra-icon-check:before {
  content: "\e048";
}
.ra-icon-up:before {
  content: "\e126";
}
.ra-icon-down:before {
  content: "\e127";
}
.ra-icon-phone-on:before {
  content: "\e075";
}
.ra-icon-email-on:before {
  content: "\e122";
}
.ra-icon-user:before {
  content: "\e021";
}
.ra-icon-pin:before {
  content: "\e008";
}
.ra-icon-asc:before {
  content: "\e126";
}
.ra-icon-desc:before {
  content: "\e127";
}
.ra-icon-loc-pin:before, .ra-icon-loc-pin-small:before {
  content: "\e008";
}

.outlet-cont {
  padding: 5px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  .outlet {
    background-image: url("../../assets/smile.svg");
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    background-size: contain;
    background-position: center;
  }
}
