.outlet {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 60px max-content auto;
  .outlet-content {
      padding: 24px;
    .outlet-title {
      font-family: Roboto-Medium;
      font-size: 24px;
      color: #000000;
      text-align: left;
      margin-bottom: 24px;
    }
    .outlet-card {
        margin-top: 24px;
    }
  }
}
