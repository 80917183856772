@import "../common/global.scss";

.assign {
  height: 100%;
  width: 100%;
  position: absolute;
  display: grid;
  grid-template-rows: 60px 50px max-content max-content auto;
  .assign-info {
    width: 100%;
    height: 100%;
    padding: 24px 24px 24px 24px;
    background-color: $white;
    .assign-info-label {
      font-family: Roboto-MediumItalic;
      font-size: 18px;
      color: $red;
      letter-spacing: 0;
      text-align: left;
      margin-top: 16px;
    }
  }
  .assign-form {
    // margin-top: 24px;
    padding: 24px 24px 8px 24px;
    background-color: $gray;
    text-align: left;
    .assign-form-title {
      font-family: Roboto-Medium;
      font-size: 24px;
      color: $black;
      text-align: left;
    }
    .assign-form-field {
      margin-top: 16px;
      margin-bottom: 16px;
      .assign-form-field-label {
        font-family: Roboto-Regular;
        font-size: 18px;
        line-height: 20px;
        text-align: left;
        margin-bottom: 12px;
      }
    }
  }
}
