@import "../../common/global.scss";

.outlets {
  .buttons {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .button-new {
      width: fit-content;
    }
  }
}
.image {
  // width: 100px;
  // height: 100px;
  margin-bottom: 24px;
}

.outlets-edit {
  text-align: left;
  .banner {
    margin-bottom: 24px;
  }
  .delete {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .field>label {
    font-size: 14px !important;
  }
  .outlets-edit-lr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    .outlets-edit-left {
      width: 48%;
      .outlets-edit-left-title {
        font-family: Roboto-Medium;
        font-size: 24px;
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
    .outlets-edit-right {
      width: 48%;
      display: flex;
      flex-direction: column;
      .outlets-edit-label {
        align-self: center;
        margin-bottom: 24px;
        &>span {
          color: $blue;
        }
      }
    }
  }
  .subscript {
    font-family: Roboto;
    font-weight: 300;
    font-size: 12px;
    margin-top: 5px;
  }
  .header-edit {
    border-bottom: $grayBorder solid 4px;
    margin-top: 20px;
    // display: grid;
    // grid-template-rows: 50% max-content;
    .header-banner {
      padding: 16px;
      background-color: $menugray;
      display: grid;
      grid-template-columns: 50px auto max-content;
      grid-column-gap: 16px;
      border-radius: 6px;
      .header-banner-cancel {
        font-family: Roboto-Medium;
        font-size: 14px;
        align-self: center;
        &>span {
          color: $blue;
        }
      }
      .header-banner-outlet-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: $green;
        border-radius: 6px;
        width: 50px;
        height: 50px;
        align-self: center;
        .header-banner-outlet {
          background: url("../../../assets/smile.svg");
          background-repeat: no-repeat;
          height: 40px;
          width: 40px;
          display: block;
          background-size: contain;
          background-position: center;
        }
      }
    }
    .header-title {
      font-family: Roboto-Medium;
      font-size: 36px;
      line-height: 38px;
      color: $black;
      .header-title-code-cn {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        .header-title-code {
          font-size: 31px;
          font-family: Roboto-Medium;
          line-height: 34px;
        }
        .header-title-name {
          font-size: 20px;
          font-family: Roboto;
          font-weight: 400;
          margin-left: 8px;
          line-height: 22px;
          color: $gray7;
        }
      }
      .header-title-location {
        font-size: 21px;
        font-family: Roboto-Medium;
        color: $ragray;
        line-height: 29px;
      }
    }
    .header-subtitle {
      margin-top: 20px;
      margin-bottom: 20px;
      font-family: Roboto-Medium;
      font-size: 36px;
      line-height: 38px;
      color: $black;
      background-color: $white;
    }
  }
}

.upload {
  display: none;
  flex-direction: row;
}
.upd-buttons {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 48px;
  &-update {
    background-color: $green;
    color: $white;
    font-family: Roboto-Medium;
    font-size: 14px;
    padding: 6px 12px 6px 12px;
    border-radius: 6px;
  }
  &-or {
    margin-left: 5px;
  }
  &-cancel {
    margin-left: 5px;
    color: $blue;
  }
}