@import "../common/global.scss";

.history {
  background-color: $white;
  .outlet-card {
    padding: 24px;
    border-bottom: 1px $gray solid;
  }
  .history-alert {
    padding: 16px 24px 16px 24px;
    text-align: left;
    border-bottom: 1px solid $gray;
    .history-date {
      font-family: Roboto-Medium;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 28px;
      margin-bottom: 7px;
    }
    .history-ago {
      font-family: Roboto-Italic;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
    }
    .history-user-comment {
      font-family: Roboto-Italic;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
      display: flex;
      flex-direction: row;
      .history-user {
        color: $blue;
        margin-right: 12px;
      }
    }
    .history-user {
      font-family: Roboto-Italic;
      font-size: 16px;
      color: $blue;
    }
    .history-description {
      font-family: Roboto-Italic;
      font-size: 16px;
      line-height: 20px;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &:nth-child(even) {
      background-color: $lightGray;
    }
  }
}
