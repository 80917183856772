@import "./font.scss";
@import url("//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css");

$ipadp: "only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)";
$mon: "only screen and (min-device-width : 1024px)";

$blue: #0d83dd;
$bluebg: #e2f1fb;
$bluesort: #006ac4;
$green: #65b10d;
$greentint: #f0f7e7;
$lightGreen: #f0f7e7;
$white: #ffffff;

$gray: #e6e6e6;
$graytint: #f4f4f4;
$lightGray: #f6f6f6;
$darkgray: #b9b9b9;
$menugray: #f8f8f8;
$underlineGray: #d8d8d8;
$menuborder: #e7e7e7;
$graybg: #f4f4f4;
$grayf7: #f7f7f7;

$grayBorder: #eaeaea;

$ragray: #999999;
$gray7: #777777;
$black: #000000;
$red: #fc0f01;
$lightRed: #fee7e5;

$orange: #ff9426;
$lightOrange: #fff4e9;

$overscroll: #e7f3fc;

.pointer {
  cursor: pointer;
}

.ft-medium-italic {
  font-family: Roboto-MediumItalic;
}
.ft-red {
  color: $red;
}
.ft-gray {
  color: $ragray;
}
.ft-blue {
  color: $blue;
}
.ft-black {
  color: $black;
}

.cnew {
  color: $red;
  fill: $red;
}
.copen {
  color: $orange;
  fill: $orange;
}
.cclosed {
  color: $green;
  fill: $green;
}
.chold {
  color: $ragray;
}
.carch {
  color: $black;
}
.cno {
  color: $gray;
}

.bgno {
  background-color: $gray;
}
.bgnew {
  background-color: $red;
}
.bganew {
  background-color: $lightRed;
}
.bgopen {
  background-color: $orange;
}
.bgaopen {
  background-color: $lightOrange;
}
.bgclosed {
  background-color: $green;
}
.bgaclosed {
  background-color: $greentint;
}
.bghold {
  background-color: $ragray;
}
.bgahold {
  background-color: $graytint;
}
.bgarch,
.bgarchived {
  background-color: $black;
}

body {
  .modal {
    margin-top: 110px !important;
    width: 90% !important;
    height: auto;
    position: absolute;
    left: 5%;
    .modal-header {
      z-index: -10;
      background-color: $blue !important;
    }
    .modal-text {
      text-align: center;
      margin-top: 12px;
      .modal-text-title {
        font-family: Roboto-Medium;
        font-size: 28px;
        color: #0d83dd;
      }
      .modal-text-subtitle {
        font-family: Roboto-Regular;
        font-size: 16px;
        color: #000000;
      }
    }
    .successGuy {
      background: url("../../assets/guy.svg");
      background-repeat: no-repeat;
      height: 30vh;
      display: block;
      margin: auto;
      background-size: contain;
      background-position: center;
      margin-top: -50px;
      z-index: 10;
    }
  }
}
.title {
  margin-top: 10vh;
  color: $white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 58px;
  align-items: center;
  .title-bold {
    font-family: Roboto-Medium;
  }
  .title-thin {
    font-family: Roboto-Thin;
  }
  margin-bottom: 32px;
  .smile {
    background: url("../../assets/smile.svg");
    background-repeat: no-repeat;
    height: 68px;
    width: 16vw;
    display: block;
    background-size: contain;
    background-position: center;
    margin-right: 8px;
  }
}

.button {
  font-family: Roboto-Medium;
  font-size: 24px;
  background-color: $green;
  color: $white;
  display: block;
  padding: 12px 24px 12px 24px;
  border-radius: 6px;
  line-height: 25px;
  &.disabled {
    background-color: $darkgray;
  }
  &.cancel {
    font-size: 16px;
    background-color: $white;
    color: $ragray;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  &.submit {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  div {
    margin-left: 16px;
  }
  div:first-child {
    margin-left: 0px;
  }
  .buttons-right {
    display: flex;
    flex-direction: row;
  }
}

* {
  box-sizing: border-box;
}

.custom-check-box-wrapper {
  display: inline-block;
  font-size: 18px;
  line-height: 21px;
}
.custom-check-box-label {
  font-family: Roboto-Regular;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 0;
}
.custom-check-box {
  width: 26px;
  position: relative;
  display: inline-block;
  label {
    width: 18px;
    height: 18px;
    cursor: pointer;
    position: absolute;
    top: 2px;
    left: 0;
    background: #ffffff;
    background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
    border-radius: 4px;
    //box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
    &:after {
      content: "";
      width: 12px;
      height: 8px;
      position: absolute;
      top: 2px;
      left: 2px;
      border: 3px solid #0d83dd;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }
    &:hover::after {
      opacity: 0;
    }
  }
  input[type="checkbox"] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }
}

.header {
  display: grid;
  grid-template-columns: 20% auto 20%;
  background-color: $black;
  color: $white;
  padding: 0px 24px 0px 24px;
  position: relative;
  align-items: baseline;
  height: 60px;
  line-height: 57px;
  .header-menu {
    justify-self: left;
    font-size: 26px;
  }
  .header-add {
    justify-self: right;
    font-family: Roboto-Medium;
    font-size: 18px;
  }
  .header-title {
    justify-self: center;
    font-family: Roboto-Medium;
    font-size: 24px;
  }
  .header-screen {
    display: none;
    background-color: $green;
    line-height: initial;
    color: $white;
    position: absolute;
    top: 0;
    right: 0;
  }
}
.status {
  font-family: Roboto-Medium;
  font-size: 19px;
  line-height: 50px;
  padding: 0px 24px 0px 24px;
  text-align: left;
  color: $white;
  &-open {
    background-color: $orange;
  }
  &-new {
    background-color: $red;
  }
  &-closed {
    background-color: $green;
  }
  &-archived {
    background-color: $black;
  }
  &-hold {
    background-color: $ragray;
  }
  &-history {
    background-color: $blue;
  }
}
.overscroll {
  background-color: $overscroll;
  min-height: 270px;
  padding: 24px;
}
.outlet-card {
  display: flex;
  flex-direction: column;
  text-align: left;
  line-height: 28px;
  position: relative;
  .outlet-card-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .outlet-card-cnt {
    position: absolute;
    right: 0;
    top: -10px;
    background-color: $blue;
    font-family: Roboto-Medium;
    font-size: 20px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    color: $white;
    width: 38px;
    border-radius: 50%;
  }
  .outlet-card-type {
    font-family: Roboto-Regular;
    font-size: 18px;
  }
  .outlet-card-id {
    font-family: Roboto-Medium;
    font-size: 24px;
    line-height: 32px;
    color: $red;
  }
  .outlet-card-location {
    font-family: Roboto-Medium;
    font-size: 20px;
    line-height: 22px;
    color: $black;
  }
}

.container-main {
  background-color: $white;
  .top {
    padding-left: 150px;
    padding-right: 150px;
  }
  .content {
    margin-left: 150px;
    margin-right: 150px;
  }
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.top {
  background-color: $blue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .top-logo {
  }
  .top-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .item {
      background-color: $blue;
      color: $white;
      font-family: Roboto-Regular;
      font-size: 18px;
      line-height: 24px;
      padding: 24px 0 24px 24px;
    }
  }
  .title {
    margin: 0px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .smile {
      width: 38px;
      height: 38px;
    }
    .title-bold {
      font-size: 37px;
    }
    .title-thin {
      font-size: 37px;
    }
  }
}
.org-user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 18px;
  padding-bottom: 18px;
  .org-user-org {
    font-family: Roboto-Medium;
    font-size: 28px;
    color: #000000;
    letter-spacing: 0;
  }
  .org-user-user {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .org-user-user-main {
      font-family: Roboto-Medium;
      font-size: 24px;
      color: #000000;
      letter-spacing: 0;
    }
    .org-user-user-add {
      font-family: Roboto-Regular;
      font-size: 18px;
      color: #999999;
      letter-spacing: 0;
    }
  }
}
.ra-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: $menugray;
  border: $menuborder 1px solid;
  border-radius: 6px;
  .menu-item {
    padding-left: 12px;
    padding-right: 12px;
    font-family: Roboto-Regular;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    line-height: 56px;
    &.active {
      background-color: $gray;
    }
  }
}

.ra-breadcrumb {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .breadcrumb-left {
    display: flex;
    flex-direction: row;
    .separator {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $blue;
  border-radius: 6px;
  margin-top: 18px;
  padding: 50px;
  .banner-title {
    font-family: Roboto-Bold;
    font-size: 64px;
    color: #ffffff;
    letter-spacing: 0;
    line-height: 66px;
  }
  .banner-subtitle {
    font-family: Roboto-Regular;
    font-size: 32px;
    color: #ffffff;
    letter-spacing: 0;
    line-height: 34px;
    text-align: left;
  }
  .banner-subtitle-small {
    width: 85%;
    font-size: 24px;
  }
}

.charts-show-hide {
  font-family: Roboto-Regular;
  font-size: 18px;
  letter-spacing: 0;
  text-align: right;
  margin-top: 16px;
  margin-bottom: 16px;
  & .active {
    color: $blue;
  }
  & .gray {
    color: $gray;
  }
}
.date {
  text-align: left;
  .date-date-gray {
    font-family: Roboto-Medium;
    font-size: 16px;
    line-height: 18px;
    color: $ragray;
  }
  .date-time {
    font-family: Roboto-Medium;
    font-size: 14px;
    line-height: 17px;
    color: $black;
    white-space: nowrap;
    margin-bottom: 8px;
  }
}
.rtable {
  width: 100%;
  border-collapse: collapse;
  // border: 1px solid #d6d6d6;
  tr:nth-child(even) {
    background: #ffffff;
  }
  tr:nth-child(odd) {
    background: #f7f7f7;
  }
  td {
    border-left: 1px solid #d6d6d6;
    border-top: 1px solid #d6d6d6;
    line-height: 18px;
    font-family: Roboto-Medium;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    padding: 12px;
    .ft-gray {
      color: #999999 !important;
    }
    .ft-red {
      color: #fc0f01 !important;
    }
    .ft-blue {
      color: #0d83dd !important;
    }
    .ft-green {
      color: #65b10d !important;
    }
    .ft-black {
      color: #000000 !important;
    }
    .ft-yellow {
      color: #ffce13 !important;
    }
    .ft-orange {
      color: #ff9426 !important;
    }
  }
  th {
    border-left: 1px solid #d6d6d6;
    line-height: 56px;
    border-top: 1px solid #d6d6d6;
    user-select: none;
    position: relative;
    background: #ffffff;
    font-family: Roboto-Medium;
    font-size: 18px;
    padding: 12px;
    white-space: nowrap;
    .ra-icon-asc,
    .ra-icon-desc {
      margin-left: 6px;
    }
  }
  th.bottom-border {
    border-bottom: 1px solid #d6d6d6;
  }
  td:first-of-type,
  th:first-of-type {
    border-left: 1px solid #d6d6d6;
  }
  td:last-of-type,
  th:last-of-type {
    border-right: 1px solid #d6d6d6;
  }
  tr:last-of-type {
    td {
      border-bottom: 1px solid #d6d6d6;
    }
  }
  .center {
    text-align: center;
  }
  .left {
    text-align: left;
  }
  .nowrp {
    white-space: nowrap;
  }
  tfoot {
    tr {
      td,
      td:first-of-type,
      td:last-of-type {
        border: none !important;
        line-height: 26px;
        font-size: 24px;
      }
    }
  }
}
.rtable-empty {
  width: 100%;
  background: #f7f7f7;
  border-left: 1px solid #d6d6d6;
  border-right: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .rtable-empty-title {
    font-family: Roboto-Medium;
    font-size: 30px;
    line-height: 32px;
  }
  .rtable-empty-subtitle {
    font-family: Roboto-Regular;
    font-size: 21px;
    line-height: 23px;
    margin-top: 10px;
  }
}

.pipe-filter {
  display: flex;
  flex-direction: row;
  font-family: Roboto-Medium;
  font-size: 18px;
  letter-spacing: 0;
}
.title {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  .title-caption {
    font-family: Roboto-Medium;
    font-size: 36px;
    color: #000000;
    letter-spacing: 0;
  }
}
.title-global {
  border-bottom: #999999 solid 1px;
  justify-content: space-between;
  padding-bottom: 12px;
}
.title-periods {
  font-family: Roboto-Regular;
  font-size: 18px;
  color: #0d83dd;
  letter-spacing: 0;
  .title-periods-delimiter {
    color: #999999;
    margin-left: 8px;
    margin-right: 8px;
  }
  .title-periods-value {
    color: #0d83dd;
  }
}
.period-select {
  min-width: 15vw;
  margin-left: 16px;
}
.period {
  display: flex;
  flex-direction: column;
  .period-caption {
    font-family: Roboto-Italic;
    font-size: 18px;
    color: #999999;
    letter-spacing: 0;
    line-height: 20px;
    border-top: #d8d8d8 1px solid;
    text-align: center;
  }
}
.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .toolbar-left,
  .toolbar-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    .toolbar-left-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
.top-filter {
  margin-top: 12px;
  background-color: $white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .filter {
    display: flex;
    flex-direction: row;
    text-align: left;
    .filter-select {
      min-width: 15vw;
      margin-left: 24px;
    }
    .filter-select:first-of-type {
      margin-left: 0px;
    }
  }
}

.search {
  align-self: center;
  max-width: 20vw;
  width: 100%;
  input {
    font-size: 16px;
    font-family: Roboto-Regular;
    border: 1px solid #d6d6d6;
    border-radius: 6px;
    line-height: 34px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 12px;
  }
}
.r-button {
  color: #0d83dd;
  border: 1px solid #0d83dd;
  border-radius: 6px;
  padding: 10px 16px 10px 16px;
  cursor: pointer;
  background-color: #ffffff;
  font-family: Roboto-Regular;
  font-size: 16px;
  line-height: 19px;
}

.chart-title {
  font-family: Roboto-Medium;
  font-size: 24px;
  color: #000000;
  letter-spacing: 0;
  margin: 0px 0px 32px 24px;
  padding-top: 16px;
}
.chart {
  .chart-container {
    display: flex;
    flex-direction: row;
    .y-axis {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 40px;
      font-family: Roboto-Medium;
      font-size: 18px;
      color: #000000;
    }
  }
  .chart-captions {
    display: flex;
    flex-direction: row;
    height: 56px;
    margin-top: 8px;
    .chart-caption {
      position: absolute;
    }
    .caption-num {
      font-family: Roboto-Medium;
      font-size: 18px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      line-height: 21px;
    }
    .caption-day {
      font-family: Roboto-Medium;
      font-size: 18px;
      color: #999999;
      letter-spacing: 0;
      text-align: center;
      line-height: 21px;
    }
  }
  .chart-line {
    stroke: #d8d8d8;
    stroke-width: 1px;
  }
}

.ra-footer {
  height: 200px;
}

.slide-one {
  cursor: pointer;
  width: 40px;
  height: 14px;
  background: #8bccec;
  margin: 18px;
  position: relative;
  border-radius: 50px;
  &.gray {
    background-color: #999999;
  }
  label {
    cursor: pointer;
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -3px;
    left: -3px;
    background-color: #039be5;
    border-radius: 50px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
    transition: all 0.4s ease;
    color: #0d83dd;
    margin: auto;
  }
  label.gray {
    background-color: #999999;
  }
  input[type="checkbox"] {
    visibility: hidden;
  }
  input[type="checkbox"]:checked + label {
    left: 20px;
  }
}
.gray-switch {
  color: #999999;
}
.gray-switch-bc {
  background-color: #f1f1f1 !important;
}
.gray-switch-bc-bc {
  background-color: #999999 !important;
}

.option {
  display: inline;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 25px;
  input {
    display: none;
    position: absolute;
  }
  .option-mod {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #999999;
  }
  .option-mod:after {
    content: "";
    position: absolute;
    display: none;
  }
}
.option input:checked ~ .option-mod {
  /* background-color: #2196F3; */
  border: #0d83dd 3px solid;
}
.option input:checked ~ .option-mod:after {
  display: block;
}
.option .option-mod:after {
  top: 3px;
  left: 3px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #0d83dd;
}
