@import "../common/global.scss";

.plug {
  background-color: $lightRed;
  border-bottom: 1px solid $darkgray;
  padding: 16px 24px 16px 24px;
  display: grid;
  grid-template-columns: 95% 5%;
  .plug-content {
    .plug-content-col {
      display: grid;
      grid-template-columns: 25% 8px calc(75% - 8px);
      margin-bottom: 14px;
      .plug-picture {
        width: 78px;
        height: 78px;
      }
      .plug-text {
        justify-items: left;
        .plug-date {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          .plug-time {
            font-family: Roboto-Medium;
            font-size: 18px;
            line-height: 22px;
            color: $red;
          }
          .plug-ampm {
            font-family: Roboto-Medium;
            font-size: 12px;
            line-height: 22px;
            color: $red;
          }
          .plug-data {
            font-family: Roboto-Regular;
            font-size: 18px;
            line-height: 22px;
            &-new {
              color: $black;
            }
            &-old {
              color: $red;
            }
          }
        }
        .plug-outlet {
          font-family: Roboto-Medium;
          font-size: 20px;
          line-height: 22px;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          &-black {
            color: $black;
          }
          &-red {
            color: $red;
          }
        }
        .plug-location {
          font-family: Roboto-Medium;
          font-size: 20px;
          line-height: 22px;
          height: 44px;
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          &-black {
            color: $black;
          }
          &-red {
            color: $red;
          }
        }
      }
    }
  }
  .plug-ago {
    font-family: Roboto-Italic;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
  }
  .plug-description {
    font-family: Roboto-Italic;
    font-size: 18px;
    line-height: 24px;
    height: 48px;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .plug-resolved {
    font-family: Roboto-Italic;
    font-size: 16px;
    line-height: 19px;
    height: 38px;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .plug-more {
    justify-self: right;
    align-self: center;
    font-size: 20px;
    // padding: 12px;
    &-new {
      color: $red;
    }
    &-open {
      color: $orange;
    }
    &-closed {
      color: $green;
    }
  }
  .plug-user {
    font-family: Roboto-Italic;
    font-size: 16px;
    line-height: 19px;
    color: $blue;
  }
  &.status-open {
    background-color: $lightOrange;
    height: 185px;
  }
  &.status-new {
    background-color: $lightRed;
    height: 185px;
  }
  &.status-closed {
    background-color: $lightGreen;
    height: 194px;
  }
  &.status-archived, &.status-hold {
    background-color: $lightGray;
    height: 194px;
  }
}

.list.modal.sort {
  .header {
    font-family: Roboto-Medium !important;
    font-size: 20px;
    color: $white;
    line-height: 29px;
    height: 50px;
    position: relative;
    padding-left: 24px !important;
    font-weight: normal !important;
    line-height: 28px !important;
    .ra-icon-cross {
      position: absolute;
      top: 11px;
      right: 24px;
      font-size: 15px !important;
    }
  }
  .content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .sort-piece {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $darkgray;
      .sort-piece-label {
        font-family: Roboto-Light;
        font-size: 18px;
        line-height: 49px;
        &-active {
          color: $bluesort;
          font-family: Roboto-Regular;
        }
      }
    }
    .sort-piece-value {
      color: $bluesort;
    }
    .sort-piece:last-of-type {
      border-bottom: none;
    }
  }
  .actions {
    background-color: $bluebg !important;
    padding: 16px 21px 16px 21px !important;
    .footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 36px;
      .button {
        height: 36px;
        width: 50%;
        text-align: center;
        font-family: Roboto-Medium;
        font-size: 18px;
        line-height: 21px;
      }
      .button:first-of-type {
        margin-right: 16px;
      }
    }
  }
}
