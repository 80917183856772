@import "../../common/global.scss";

.users {
  .buttons {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .buttons-title {
      font-family: Roboto-Medium;
      font-size: 32px;
      letter-spacing: 0;
    }
    .button-new {
      width: fit-content;
    }
  }
  .users-table {
    .cell-image {
      font-size: 50px;
      line-height: 40px;
    }
    .cell-status {
      font-size: 70px;
      line-height: 40px;
    }
  }
}

.users-edit {
  text-align: left;
  .banner {
    margin-bottom: 24px;
  }
  .delete {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .users-edit-header {
    width: 100%;
    background-color: $grayf7;
    margin-top: 20px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    .users-edit-header-left {
      display: flex;
      flex-direction: column;
      width: 15%;
      margin-right: 49px;
      .tech {
        background: url("../../../assets//tech.svg");
        background-repeat: no-repeat;
        height: 155px;
        width: 155px;
        display: block;
        margin: auto;
        background-size: contain;
        background-position: center;
      }
      .tech-id {
        text-align: center;
        margin-top: 2px;
        font-family: Roboto-Regular;
        font-size: 18px;
        line-height: 21px;
        color: $ragray;
      }
    }
    .users-edit-header-right {
      width: calc(85% - 49px);
      .ue-name {
        font-family: Roboto-Bold;
        font-size: 32px;
        color: #000000;
      }
      .ue-info {
        font-family: Roboto-Regular;
        font-size: 20px;
        color: #000000;
        margin-top: 12px;
        .ue-info-permission {
          color: $red;
          font-family: Roboto-Medium;
        }
      }
      .ue-contacts {
        display: flex;
        flex-direction: row;
        margin-top: 26px;
        .ue-contact {
          display: flex;
          flex-direction: row;
          width: 25%;
          .ue-icon {
            font-size: 63px;
            color: $blue;
            margin-right: 8px;
          }
          .ue-contact-info {
            display: flex;
            flex-direction: column;
            font-family: Roboto-Regular;
            font-size: 18px;
            line-height: 21px;
            .ue-contact-info-label {
              color: $ragray;
            }
            .ue-contact-info-data {
              color: $blue;
            }
          }
        }
      }
      .ue-notes {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
        align-items: baseline;
        .ue-notes-caption {
          font-family: Roboto-Regular;
          font-size: 20px;
          line-height: 24px;
          color: $ragray;
          margin-right: 6px;
        }
        .ue-notes-data {
          font-family: Roboto-Italic;
          font-size: 18px;
          line-height: 21px;
        }
      }
    }
  }
  .ue-assignment-title {
    margin-top: 24px;
    font-family: Roboto-Medium;
    font-size: 36px;
    color: #000000;
  }
  .slide-one {
    margin-left: auto;
    margin-right: auto;
  }
}
