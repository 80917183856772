@import "../../common/global.scss";

.home {
    text-align: left;
  .home-top {
    font-family: Roboto-Medium;
    font-size: 36px;
    background-color: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $underlineGray;
  }
  .show-hide-menu {
    margin-top: 12px;
    font-family: Roboto-Regular;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    letter-spacing: 0;
    display: flex;
    justify-content: flex-end;
  }
  .alerts {
    margin-top: 12px;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: $grayf7;
    .alerts-title {
      margin-left: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      .alerts-title-title {
        font-family: Roboto-Medium;
        font-size: 28px;
        color: #000000;
        letter-spacing: 0;
      }
    }
    .chart {
      .chart-title {
        display: none;
      }
      background-color: #ffffff;
      padding-top: 24px;
      padding-bottom: 8px;
      .chart {
        .chart-container {
          display: flex;
          flex-direction: row;
          .y-axis {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 55px;
            font-family: Roboto-Medium;
            font-size: 18px;
            color: #000000;
          }
        }
        .chart-captions {
          display: flex;
          flex-direction: row;
          height: 36px;
          margin-top: 12px;
          .chart-caption {
            position: absolute;
          }
          .caption-num {
            font-family: Roboto-Medium;
            font-size: 12px;
            line-height: 14px;
            color: #000000;
            letter-spacing: 0;
            text-align: center;
            @media #{$mon} {
              font-size: 18px;
              line-height: 21px;
            }
          }
          .caption-day {
            font-family: Roboto-Medium;
            font-size: 12px;
            line-height: 14px;
            color: #999999;
            letter-spacing: 0;
            text-align: center;
            @media #{$mon} {
              font-size: 18px;
              line-height: 21px;
            }
          }
        }
        .chart-line {
          stroke: #d8d8d8;
          stroke-width: 1px;
        }
      }
    }
  }
  .home-data {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 60% 40%;
    @media #{$mon} {
      grid-template-columns: 42% 58%;
    }
    line-height: 1;
    .home-data-locations {
      background-color: #f7f7f7;
      // width: 42%;
      overflow: hidden;
      position: relative;
      .home-data-locations-title {
        font-family: Roboto-Medium;
        font-size: 28px;
        line-height: 31px;
        color: #ffffff;
        letter-spacing: 0;
        background-color: #0d83dd;
        padding: 8px 24px 8px 24px;
      }
      .home-data-locations-container {
        padding: 0px 16px 16px 16px;
        display: grid;
        height: 100%;
        grid-template-rows: min-content min-content 20fr 1fr;
        @media #{$mon} {
          padding: 0px 24px 24px 24px;
        }
        .home-data-locations-filters {
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          .home-data-locations-filters-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .home-data-locations-filters-filter {
              flex: 1;
              margin-right: 16px;
            }
            .home-data-locations-filters-filter:nth-of-type(3) {
              margin-right: 0px;
            }
            .home-data-locations-filters-search {
              flex: 2;
              input {
                font-size: 16px;
                font-family: Roboto-Regular;
                border: 1px solid #d6d6d6;
                border-radius: 6px;
                line-height: 34px;
                width: 100%;
                box-sizing: border-box;
                padding-left: 12px;
              }
            }
          }
          .home-data-locations-filters-row:first-of-type {
            margin-bottom: 12px;
          }
        }
        .home-data-locations-subtitle {
          font-family: Roboto-Medium;
          font-size: 28px;
          line-height: 33px;
          color: #000000;
          letter-spacing: 0;
        }
        .home-data-locations-subtitle-val {
          font-family: Roboto-Bold;
          color: #fc0f01;
        }
        .row-header {
          margin-top: 16px;
          margin-bottom: 16px;
          display: grid;
          grid-template-columns: auto 20% 20%;
          .header-title {
            font-family: Roboto-Medium;
            font-size: 24px;
            color: #999999;
            letter-spacing: 0;
            text-align: center;
            line-height: 33px;
            &:last-child {
                text-align: right;
            }
          }
        }
        .row {
          // display: flex;
          // flex-direction: row;
          display: grid;
          grid-template-columns: 15% auto 20% 20%;
          margin-top: 10px;
          margin-left: 0px;
          margin-right: 0px;
          .row-pin {
            width: 12%;
          }
          .ra-icon-loc-pin {
            width: 10%;
            font-size: 66px;
            margin-top: -10px;
            margin-left: 8px;
          }
          .ra-icon-loc-pin-small {
            width: 10%;
            font-size: 51px;
            margin-top: -10px;
            margin-left: 12px;
            position: relative;
          }
          .loc-pin-small-primary {
            position: absolute;
            font-family: Roboto-Medium;
            font-size: 12px;
            color: #000000;
            top: 56px;
            left: -12px;
          }
          .row-name {
            // width: 45%;
            text-align: left;
            .row-name-name {
              font-family: Roboto-Medium;
              font-size: 20px;
              color: #0d83dd;
              letter-spacing: 0;
              line-height: 24px;
            }
            .row-name-city {
              font-family: Roboto-Regular;
              font-size: 18px;
              color: #000000;
              letter-spacing: 0;
              line-height: 22px;
            }
            .row-name-action {
              font-family: Roboto-Regular;
              font-size: 18px;
              color: #999999;
              letter-spacing: 0;
              line-height: 20px;
              display: flex;
              flex-direction: row;
              .ago-val {
                font-family: Roboto-Regular;
                font-size: 20px;
                color: #000000;
              }
              .ago-val-red {
                font-family: Roboto-Medium;
                font-size: 20px;
                color: #fc0f01;
              }
              .ago-label {
                font-family: Roboto-Medium;
                font-size: 18px;
                color: #999999;
              }
            }
          }
          .row-val {
            // width: 20%;
            font-family: Roboto-Medium;
            font-size: 28px;
            letter-spacing: 0;
            line-height: 34px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .direction {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 12px;
              margin-top: 12px;
              .dir-up {
                fill: #65b10d;
              }
              .dir-down {
                fill: #fc0f01;
              }
            }
          }
          .row-val-blue {
            color: #0d83dd;
            align-items: flex-start;
          }
          .row-val-red {
            color: #fc0f01;
            align-items: flex-start;
          }
        }
        .home-data-locations-footer {
          font-family: Roboto-Bold;
          font-size: 16px;
          color: #0d83dd;
          text-align: center;
          padding-top: 24px;
          padding-bottom: 24px;
          position: absolute;
          bottom: 0;
          width: 100%;
          background-color: #f7f7f7;
        }
      }
    }
    .home-data-events {
      display: flex;
      flex-direction: column;
      flex: 1;
      // flex-wrap: wrap;
      // width: 42%;
      height: 100%;
      .home-data-events-row {
        display: flex;
        flex-direction: row;
        .home-data-events-event {
          background-color: #f7f7f7;
          margin-left: 2%;
          padding: 16px 24px 16px 24px;
          // width: 45%;
          flex: 1;
          .event-title {
            font-family: Roboto-Medium;
            font-size: 28px;
            line-height: 31px;
            color: #000000;
            letter-spacing: 0;
            .event-title-period {
              color: #999999;
              font-size: 24px;
              letter-spacing: 0;
            }
            &-left {
              text-align: left;
            }
          }
          .value-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            &-center {
              align-items: center;
            }
            &-baseline {
              align-items: baseline;
            }
            .ind {
              font-family: Roboto-Medium;
              font-size: 90px;
              // color: #0d83dd;
              letter-spacing: 0;
              line-height: 112px;
              text-align: center;
            }
            .value-unit {
              font-family: Roboto-Medium;
              font-size: 40px;
              color: $ragray;
            }
          }
        }
      }
      .event-outlets {
        display: grid;
        grid-template-columns: minmax(0, 60%) 25% 15%;
        grid-row-gap: 24px;
        .event-outlets-title {
          font-family: Roboto-Medium;
          font-size: 24px;
          color: $ragray;
          &-right {
            text-align: right;
          }
      }
        .events-outlet {
          display: flex;
          flex-direction: row;
          .events-outlet-info {
            text-align: left;
            margin-left: 16px;
            max-width: calc(100% - 80px - 16px);
            .events-outlet-title {
              font-family: Roboto-Regular;
              font-size: 18px;
              line-height: 28px;
              width: 100%;
              white-space: nowr ap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .events-outlet-code {
              font-family: Roboto-Medium;
              font-size: 24px;
              line-height: 28px;
              width: 100%;
              white-space: nowrap;
              overflow-x: hidden;
              text-overflow: ellipsis;
            }
            .events-outlet-location {
              font-family: Roboto-Medium;
              font-size: 20px;
              line-height: 23px;
              width: 100%;
              white-space: nowrap;
              overflow-x: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .events-outlet-alerts {
          font-family: Roboto-Medium;
          font-size: 28px;
          line-height: 34px;
          color: $red;
          text-align: center;
        }
        .events-outlet-last {
          font-family: Roboto-Regular;
          font-size: 20px;
          line-height: 34px;
          text-align: right;
        }
      }
      //   .home-data-events-row:nth-of-type(1) {
      //     .home-data-events-event:nth-of-type(2) {
      //       display: none;
      //       @media #{$mon} {
      //         display: block;
      //       }
      //     }
      //   }
      .home-data-events-row:nth-of-type(2),
      .home-data-events-row:nth-of-type(3) {
        margin-top: 24px;
        //     .home-data-events-event:nth-of-type(1) {
        //       display: block;
        //       @media #{$mon} {
        //         display: none;
        //       }
      }
      //     .home-data-events-event:nth-of-type(2), .home-data-events-event:nth-of-type(3) {
      //       display: none;
      //       @media #{$mon} {
      //         display: block;
      //       }
      //     }
      //   }
      .home-data-events-row-ipad {
        display: block;
        @media #{$mon} {
          display: none;
        }
      }
    }
  }
  //   .home-data:last-of-type {
  //     grid-template-columns: 60% 40%;
  //     @media #{$mon} {
  //       grid-template-columns: 100%;
  //     }
  //   }
}
