@import "../common/global.scss";

.login {
  background-color: $blue;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin-top: 10vh;
    color: $white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 58px;
    align-items: center;
    .title-bold {
      font-family: Roboto-Medium;
    }
    .title-thin {
      font-family: Roboto-Thin;
    }
    margin-bottom: 32px;
    .smile {
      background: url("../../assets/smile.svg");
      background-repeat: no-repeat;
      height: 68px;
      width: 16vw;
      display: block;
      background-size: contain;
      background-position: center;
    }
  }
  .splash {
    .man {
      background: url("../../assets/man.svg");
      background-repeat: no-repeat;
      height: 30vh;
      display: block;
      margin: auto;
      background-size: contain;
      background-position: center;
    }
    .text {
      margin-top: 24px;
      font-family: Roboto-Italic;
      font-size: 20px;
      color: #ffffff;
      text-align: center;
    }
    .button {
      margin-top: 24px;
    }
  }
  .loginform {
    background-color: $gray;
    width: 80vw;
    height: 65vh;
    border-radius: 6px;
    padding: 24px 32px 24px 32px;
    .loginform-title {
      font-family: Roboto-Medium;
      font-size: 28px;
      color: #000000;
      text-align: left;
      line-height: 30px;
    }
    .field {
      margin-top: 12px;
      .field-title {
        font-family: Roboto-Regular;
        font-size: 18px;
        color: #000000;
        text-align: left;
      }
      input {
        font-family: Roboto-Medium;
        font-size: 18px;
        border-radius: 6px;
        border-style: none;
        padding: 12px;
        font-family: Roboto-Regular;
        width: 100%;
        margin-top: 12px;
      }
      input:focus {
        outline: none;
      }
    }
    .keep {
      margin-top: 12px;
      width: 100%;
      text-align: left;
    }
    .addinfo {
      font-family: Roboto-Regular;
      font-size: 14px;
      color: #999999;
      text-align: left;
      margin-top: 8px;
    }
    .button {
      margin-top: 24px;
    }
    .login-failure {
      animation-name: login-failure;
      animation-duration: .5s;
    }
    .version {
      font-family: Roboto-Regular;
      font-size: 14px;
      color: #999999;
      text-align: center;
      margin-top: 12px;
    }
    @keyframes login-failure {
      0% { margin-left: 50px; margin-right: 0px;}
      20% { margin-left: 0px; margin-right: 50px;}
      40% { margin-left: 50px; margin-right: 0px;}
      60% { margin-left: 0px; margin-right: 50px;}
      80% { margin-left: 50px; margin-right: 0px;}
      100% { margin-left: 0px; margin-right: 0px;}
    }
  }
  .navigator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      margin-bottom: 0;
      margin-top: 0;
    }
    .uptitle {
      font-family: Roboto-Medium;
      font-size: 21px;
      color: #ffffff;
      text-align: left;
      line-height: 30px;
      margin-top: calc(10vh - 16px);
      margin-bottom: 16px;
    }
    .menu-title {
      margin-top: 28px;
      font-family: Roboto-Medium;
      font-size: 18px;
      color: #ffffff;
      text-align: left;
      line-height: 30px;
    }
    .alert-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 60vw;
      // background-color: $green;
      padding: 14px 9px 16px 24px;
      border-radius: 6px;
      color: $white;
      font-family: Roboto-Medium;
      font-size: 24px;
      margin-top: 24px;
      height: 50px;
      .alert-group-flag {

      }
      .alert-group-left {
        display: flex;
        flex-direction: row;
        align-self: center;
      }
      .alert-group-count {
          // background-color: $white;
          // color: green;
          font-size: 20px;
          line-height: 24px;
          width: 34px;
          height: 34px;
          line-height: 34px;
          border-radius: 17px;
      }
    }
  }
}
