@import "../common/global.scss";

.update {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 60px 50px max-content;
  textarea {
    &:active,
    &:focus {
      border: 1px solid $blue;
    }
    border: 1px solid $gray;
  }
  .checkbox {
    font-family: Roboto-Regular;
    &.active {
      font-family: Roboto-Medium;
    }
    label {
      font-size: 18px !important;
    }
    label:after {
      color: $blue !important;
    }
    margin-bottom: 8px;
  }
  .update-outlet {
    padding: 24px 24px 24px 24px;
    display: grid;
    grid-template-rows: max-content max-content;
    text-align: left;
    background-color: $white;
    .outlet-card {
      margin-bottom: 12px;
      .outlet-card-id {
        color: $blue;
      }
    }
    .outlet-info {
      font-family: Roboto-MediumItalic;
      font-size: 18px;
      color: $red;
      margin-top: 16px;
    }
  }
  .update-status {
    padding: 12px 24px 12px 24px;
    background-color: $graybg;
    text-align: left;
    font-family: Roboto-Regular;
    .update-status-list {
      display: flex;
      flex-direction: column;
    }
    .update-status-title {
      font-family: Roboto-Medium;
      font-size: 24px;
      color: #000000;
      margin-bottom: 12px;
    }
    .custom-check-box-wrapper {
      margin-top: 6px;
    }
    .update-status-field-title {
      font-family: Roboto-Regular;
      font-size: 18px;
      margin-top: 8px;
      margin-bottom: 12px;
    }
    textarea {
      padding: 16px 13px 16px 13px;
      font-family: Roboto-Regular;
      font-size: 18px;
      line-height: 21px;
      border-radius: 6px;
      margin-bottom: 16px;
      width: 100%;
    }
  }
  .update-history {
    .update-history-tech-title {
      font-family: Roboto-Regular;
      font-size: 18px;
      line-height: 20px;
      color: #000000;
      margin-bottom: 9px;
    }
    .update-history-data {
      font-family: Roboto-Regular;
      font-size: 18px;
      line-height: 21px;
      padding: 12px 9px 12px 9px;
      background-color: $white;
      border-top: 1px solid $darkgray;
      .update-history-date {
        margin-right: 10px;
      }
      .update-history-user {
        margin-right: 10px;
        color: $blue;
      }
      .update-history-comment {
        font-family: Roboto-Italic;
      }
    }
  }
}
